import { BrowserRouter, Route, Routes  } from "react-router-dom";
import { Navigation } from "./components";
import { Chirurgie, Endodontie, Implantologie, NotFound, Parodontologia, Pedodontie, PoliticaConfidentialitate, Protetica, Servicii } from "./features";
import { Home } from "./features";
import { Preturi } from "./features";
import { Contact } from "./features";
import { Footer } from "./components";
import { Doctor } from "./features/Doctor/Doctor";


function App() {

  
  return (
    <BrowserRouter>
    
      <Navigation />
      <Routes>
     
        <Route path="/" element={<Home />} />
        
        <Route path="/home" element={<Home />} />
   
       
        <Route  exact path="/servicii" element={<Servicii />}  />
        <Route  exact path="/preturi" element={<Preturi />}  />
        <Route  exact path="/contact" element={<Contact />}  />
        <Route path="/servicii/chirurgie" element={<Chirurgie />} />
        <Route path="/servicii/pedodontie" element={<Pedodontie />} />
        <Route path="/servicii/endodontie" element={<Endodontie />} />
        <Route path="/servicii/protetica" element={<Protetica />} />
        <Route path="/servicii/implantologie" element={<Implantologie />} />
        <Route path="/servicii/parodontologia" element={<Parodontologia />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/politica-confidentialitate" element={<PoliticaConfidentialitate />} />
        <Route path="/despre-noi" element={<Doctor />} />

      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
export { App };
