import { Carousel } from "react-bootstrap";
import styles from "./Doctor.module.css";
import { useState } from "react";
import { Helmet } from "react-helmet";
<Helmet>
<meta charSet="utf-8" />
<title>Despre Noi | Carpathian Dentist | Cabinet dentar </title>
<link rel="canonical" href="/despre noi" />
<meta name="description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate. Suna la 0757088840 si programeaza-te gratuit!" />
<meta name="keywords" content="dentist, cabinet dentar moieciu, dentist moieciu de jos, clinica dentara, dentist bran, dentist simon, dentist moieciu de sus, dentist brasov" />
<meta name="author" content="Carpathian Dentist" />
<meta property="og:title" content="Despre noi | Carpathian Dentist | Cabinet dentar" />
<meta property="og:description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
<meta property="og:image" content="https://i.imgur.com/gKhjL20.png" />
<meta property="og:url" content="https://example.com/my-page" />
<meta name="facebook:title" content="Despre noi | Carpathian Dentist | Cabinet dentar" />
<meta name="facebook:description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
<meta name="facebook:image" content="https://i.imgur.com/gKhjL20.png" />
<meta name="facebook:card" content="https://i.imgur.com/Rcop7CM.png" />





</Helmet>
export function Doctor(){
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
      };

    return (<>
    <h1><strong>
        Despre noi
        </strong>
    </h1>
    <div className={styles["despre_noi"]}>
    <img src="https://i.imgur.com/FS1JbI3.png" alt="doctor" className={styles["doctor"]}></img>
        <p><br></br>
Avem plăcerea de a vă anunța deschiderea noului Cabinet Stomatologic Carpathian Dentist în Moieciu de Jos!<br></br>
Aici, veți beneficia de servicii stomatologice complete, într-un mediu modern și confortabil, dedicat sănătății și frumuseții zâmbetului dumneavoastră.<br></br>
Domnul <strong>Dr. Mușuroi Cosmin Teodor </strong>este medic specialist în <strong>protetică dentară</strong>, având peste 10 ani de experiență în restabilirea funcționalității și esteticii dentare. A ajutat nenumărați pacienți să își recapete încrederea și sănătatea zâmbetului prin soluții personalizate și tratamente avansate.
Specializat în coroane, punți dentare și proteze, Dr. Mușuroi abordează fiecare caz cu profesionalism și atenție la detalii, folosind cele mai noi tehnologii și materiale de calitate superioară. 
Misiunea sa este de a oferi fiecărui pacient un zâmbet sănătos și armonios, prin tratamente la cele mai înalte standarde.
        </p>
      
    </div>
    <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        className={styles["carusel"]}
      >
        <Carousel.Item>
          <img
            src="https://i.imgur.com/OcBMmK6.png"
            alt="imagini"
            className={styles["imagini_carusel"]}
          />
      
        </Carousel.Item>

        <Carousel.Item>
          <img
            src="https://i.imgur.com/vwrvO7D.png"
            alt="imagini"
            className={styles["imagini_carusel"]}
          />
     
        </Carousel.Item>
        <Carousel.Item>
          <img
            src="https://i.imgur.com/sHNTsEM.png"
            alt="imagini"
            className={styles["imagini_carusel"]}
          />
      
        </Carousel.Item>
        <Carousel.Item>
          <img
            src="https://i.imgur.com/ajWW9R6.png"
            alt="imagini"
            className={styles["imagini_carusel"]}
          />
      
        </Carousel.Item>
        <Carousel.Item>
          <img
            src="https://i.imgur.com/8lmhuA3.png"
            alt="imagini"
            className={styles["imagini_carusel"]}
          />
      
        </Carousel.Item>
        <Carousel.Item>
          <img
            src="https://i.imgur.com/OCg3sM4.png"
            alt="imagini"
            className={styles["imagini_carusel"]}
          />
      
        </Carousel.Item>
      </Carousel>
    
    </>)

}