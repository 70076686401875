import { Card } from "react-bootstrap";
import styles from "./Protetica.module.css";
import { faBackward, faForward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";



export function Protetica(){

  
  
  
  
  return(<>
     <Helmet>
                <meta charSet="utf-8" />
                <title>Protetica | Carpathian Dentist | Cabinet dentar </title>
                <link rel="canonical" href="/servicii/protetica" />
                <meta name="description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate. Suna la 0757088840 si programeaza-te gratuit!" />
        <meta name="keywords" content="react, meta tags, seo" />
        <meta name="author" content="Carpathian Dentist" />
        <meta property="og:title" content="Protetica | Carpathian Dentist | Cabinet dentar" />
        <meta property="og:description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta property="og:image" content="https://example.com/image.jpg" />
        <meta property="og:url" content="https://example.com/my-page" />
        <meta name="facebook:title" content="Protetica | Carpathian Dentist | Cabinet dentar" />
        <meta name="facebook:description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta name="facebook:image" content="https://example.com/image.jpg" />
        <meta name="facebook:card" content="summary_large_image" />





            </Helmet>
  
  
  
  
  
  
  
  
  <div className={styles["sectiune"]}>
<Card style={{ width: '100rem' }} className={styles["card"]}>
   <div className={styles["images"]}>
     <Card.Img variant="top" src="https://i.imgur.com/eRvG3ME.png" className={styles["img"]}/>
     <Card.Img variant="top" src="https://i.imgur.com/SFxxJbF.png" className={styles["img"]}/>
     <Card.Img variant="top" src="https://i.imgur.com/6DAo167.png" className={styles["img"]}/>
     </div>
     <Card.Body>
       <h4> <strong>Protetică</strong></h4>
       <Card.Text className={styles["text"]}>
      <p> 
      Protetica dentară este o ramură a medicinei dentare care se ocupă cu înlocuirea dinților lipsă sau a țesuturilor moi cu structuri artificiale, pentru a restabili funcția masticatorie, estetica și sănătatea orală a pacienților. Aceasta implică utilizarea diferitelor tipuri de proteze dentare și tehnologii pentru a înlocui dinții lipsă sau a restaura structurile dentare afectate.</p>
       <p>Iată câteva dintre principalele aspecte ale proteticii dentare:</p>
<ul>
<li><strong>Proteze dentare fixe: </strong>Acestea includ coroanele dentare, punțile dentare și protezele dentare implant-suportate. Coroanele sunt capace care acoperă un dinte afectat sau un implant, iar punțile sunt structuri dentare fixate între dinți adiacenți pentru a înlocui unul sau mai mulți dinți lipsă. Protezele dentare implant-suportate sunt proteze dentare fixate pe implanturi dentare pentru a oferi o ancorare solidă și o funcționalitate îmbunătățită.</li>

<li><strong>Proteze dentare mobile: </strong>Acestea includ protezele dentare parțiale și protezele dentare complete. Protezele dentare parțiale sunt utilizate pentru a înlocui unul sau mai mulți dinți lipsă și sunt sprijinite de dinții naturali sau de proteze dentare fixe. Protezele dentare complete (dentare) sunt folosite atunci când toți dinții naturali lipsesc și sunt sprijinite de țesuturile moi ale gurii și/sau de osul maxilar.</li>

<li><strong>Restaurări dentare: </strong>Protetica dentară include și restaurarea structurilor dentare afectate de carii, fracturi sau alte afecțiuni. Aceasta poate implica utilizarea materialelor dentare precum compozitele dentare, ceramica sau amalgamul pentru a restabili forma și funcția dinților</li>





</ul>
<p>Protetica dentară este esențială pentru a restabili funcția masticatorie normală, pentru a îmbunătăți vorbirea și estetica și pentru a preveni complicațiile asociate cu dinții lipsă sau cu țesuturile moi afectate. Un medic dentist sau un specialist în protetică dentară poate evalua nevoile individuale ale pacientului și poate recomanda un plan de tratament personalizat pentru a îmbunătăți sănătatea orală și calitatea vieții.</p>
       </Card.Text>
     </Card.Body>
     
     <Card.Body className={styles["cardBody"]}>
   
     <FontAwesomeIcon icon={faBackward} /><Link to="/servicii/endodontie"className={styles["link"]}onClick={() => {
    window.scroll(0, 0);
  }} >&nbsp;Endodonția</Link>
     <Link to="/servicii/implantologie" className={styles["link"]}onClick={() => {
    window.scroll(0, 0);
  }} > Implantologie&nbsp;<FontAwesomeIcon icon={faForward} /></Link>
     </Card.Body>
   </Card>
   </div></>)}